<template src="./finalizeRegistration.html" />

<script>
import { useStore } from "@/store/store.js"
import mixins from "../../../../../mixins/mixins.js"
// https://www.npmjs.com/package/vue-button-spinner
import ButtonSpinner from "vue-button-spinner"

export default {
  mixins: [mixins],
  name: "finalize-registration",
  props: {
    userData: { type: Object },
  },
  components: {
    ButtonSpinner,
  },
  data() {
    return {
      user: null,
      data_faucet: "xyz",
      msgResposta: "",
      mobileIphone: false,
      cpfEncontrado: true,
      cpf: null,
      customerPlanName: null,
      name: null,
      email: null,
      phone: null,
      birthdate: null,
      gender: null,
      hasEinstein: false,
      invalidFields: {
        name: false,
        email: false,
        phone: false,
        birthdate: false,
        birthdateEmpty: false,
        zipcode: false,
        street: false,
        number: false,
        city: false,
        state: false,
        password: false,
        password2: false,
      },
      password: "",
      password2: "",
      zipcode: null,
      street: null,
      number: null,
      complement: null,
      city: null,
      state: null,
      passwordType: "password",
      fieldsWarning: false,
      weakPasswordWarning: false,
      options: "",
      genderAlertControl: false,
      termsAlertControl: false,
      whatsapp: require("../../../../../assets/whatsapp.svg"),
      isLoading: false,
      specificFieldsMessage: {
        email: "Campo obrigatório",
        phone: "Campo obrigatório",
        birthdate: "Campo obrigatório",
        cep: "CEP inválido",
        password1: "Campo obrigatório",
        password2: "Campo obrigatório",
      },
      weakPassword: "A senha deve ter ao menos 8 caracteres, letras maiúsculas, minúsculas, números e caractere especial (@!#$%).",
      isStreetDisabled: true,
      termsAccepted: false,
    };
  },
  computed: {
    isProject() {
      return this.user.redirectURL && this.user.redirectURL.includes("horas");
    },
    isShowTerms () {
      return !this.user || !this.user.termsAccepted
    },
  },

  setup () {
    const store = useStore()
    return {
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },

  mounted() {
    this.user = this.$props.userData;
    this.name = this.user.name;
    this.phone = this.user.phone;
    this.email = this.user.email;
    this.optIn = this.user.optIn;
    this.gender = this.user.gender;
    this.birthdate = this.user.birthdate
      ? this.user.birthdate.split("-").reverse().join("/")
      : null

    if (this.user.customerPlan) {
      this.customerPlanName = this.user.customerPlan.name
      this.hasEinstein = this.user.customerPlan.planServices.map(services => services.route).includes("/einstein-consulting-room")

      if (this.hasEinstein && /\b@admgestaof.com.br|@filoof.com.br|@gestaoassinatura.io\b/.test(this.email)) {
        this.email = null
      }
    }

    if (this.user.address) {
      this.zipcode = this.user.address.zipcode;

      setTimeout( () => {
        this.street = this.user.address.street;
        this.number = this.user.address.number;
        this.complement = this.user.address.complement;
        this.city = this.user.address.city;
        this.state = this.user.address.state;
      }, 200);
    } else {
      this.zipcode = null;
      this.street = null;
      this.number = null;
      this.complement = null;
      this.city = null;
      this.state = null;
    }
    this.password = null;
    
    this.detectIphone();
    this.applyMask();
  },
  methods: {
    clearButtonLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1600);
    },
    applyMask() {
      const vm = this;
      vm.phone = vm.maskCelPhone(vm.phone);
    },
    openPopupCorreios(){
      const vm = this;
      vm.$bvModal.show("finalize-registration-popup-correios");
    },
    detectIphone() {
      const vm = this;
      const uagent = navigator.userAgent.toLowerCase();
      if (uagent.search("iphone") > -1) {
        vm.mobileIphone = true;
      } else {
        vm.mobileIphone = false;
      }
    },
    passwordVisibility() {
      const vm = this;
      if (vm.passwordType === "password") {
        vm.passwordType = "text";
      } else {
        vm.passwordType = "password";
      }
    },
    testPasswordStrength(password) {
      const vm = this;
      const regex = /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      if (password == "") return;
      if (regex.test(password)) {
        vm.invalidFields.password = false;
      } else {
        vm.specificFieldsMessage.password1 = vm.weakPassword;
        vm.invalidFields.password = true;
      }
    },
    async getAddress() {
      const zipcode = this.zipcode.replace(/\D/g, "")
      let validZip = zipcode && zipcode.length === 8

      if (validZip) {
        const response = await this.getAddressByZipcode(zipcode)
        validZip = response && response.address

        if (validZip) {          
          this.street = response.address.street
          this.city = response.address.city
          this.state = response.address.state
          this.neighborhood = response.address.neighborhood
          
          this.invalidFields.zipcode = false
          this.invalidFields.city = false
          this.invalidFields.street = false
          this.invalidFields.state = false

          this.isStreetDisabled = !!this.street && !!this.state
        } else {
          console.warn(response.error)
        }
      }

      if (!validZip) {
        this.invalidFields.zipcode = true
        this.invalidFields.city = true
        this.invalidFields.street = true
        this.invalidFields.state = true
      }
    },
    checkBirthdate(birthdate) {
      const vm = this;
      if (vm.testNullUndefinedEmpty(birthdate) == false) {
        let control = false;
        const actualDate = new Date();
        const dateString = birthdate.split("/");
        const bisexttYear = parseInt(dateString[2]) % 4 == 0;
        const day = parseInt(dateString[0]);
        const month = parseInt(dateString[1]);
        if (day == 29 && month == 2 && bisexttYear) {
          control = true;
        }
        if (
          day <= 31 &&
          (month == 1 ||
            month == 3 ||
            month == 5 ||
            month == 7 ||
            month == 8 ||
            month == 10 ||
            month == 12)
        ) {
          control = true;
        }
        if ((day <= 30 && month != 2) || (day <= 28 && month == 2)) {
          control = true;
        }
        let finalDateString =
          dateString[1] + "/" + dateString[0] + "/" + dateString[2];
        finalDateString = new Date(finalDateString);
        if (finalDateString < actualDate && control) {
          return true;
        }
        return false;
      }
    },
    validateFields() {
      const vm = this;
      this.$gtm.trackEvent({
        // click_btn_pc_finalizarcadastro
        event: "finalize_registration_btn_click",
        category: "Primeiro Acesso",
        action: "finalize_registration_btn_click",
        label: "Na página de Finalizar o Cadastro no Portal Cliente - Clicar no botão “Finalizar”",
      });
      let valid = true;
      vm.isLoading = true;
      if (vm.testNullUndefinedEmpty(vm.name)) {
        valid = false;
        vm.invalidFields.name = true;
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.email)) {
        valid = false;
        vm.invalidFields.email = true;
        vm.specificFieldsMessage.email = "Campo obrigatório";
        vm.clearButtonLoading();
      } else if (vm.validateEmail(vm.email) === false) {
        valid = false;
        vm.invalidFields.email = true;
        vm.specificFieldsMessage.email = "E-mail inválido";
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.phone)) {
        valid = false;
        vm.invalidFields.phone = true;
        vm.specificFieldsMessage.phone = "Campo obrigatório";
        vm.clearButtonLoading();
      } else if (vm.phone.length < 13) {
        valid = false;
        vm.invalidFields.phone = true;
        vm.specificFieldsMessage.phone = "Número de telefone inválido";
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.password)) {
        valid = false;
        vm.invalidFields.password = true;
        vm.specificFieldsMessage.password1 = "Campo obrigatório";
        vm.clearButtonLoading();
      }
      else if (vm.testPasswordStrength(vm.password)) {
        valid = false;
        vm.invalidFields.password = true;
        vm.specificFieldsMessage.password1 = vm.weakPassword;
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.password2)) {
        valid = false;
        vm.invalidFields.password2 = true;
        vm.specificFieldsMessage.password2 = "Campo obrigatório";
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.gender)) {
        valid = false;
        vm.genderAlertControl = true;
        vm.clearButtonLoading();
      } else {
        vm.genderAlertControl = false;
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.birthdate)) {
        valid = false;
        vm.invalidFields.birthdateEmpty = true;
        vm.specificFieldsMessage.birthdate = "Campo obrigatório";
        vm.clearButtonLoading();
      } else if (vm.checkBirthdate(vm.birthdate) == false) {
        valid = false;
        vm.invalidFields.birthdate = true;
        vm.specificFieldsMessage.birthdate = "Data de nascimento inválida";
        vm.clearButtonLoading();
      }
      if (vm.invalidFields.zipcode === true) {
        valid = false;
        vm.specificFieldsMessage.cep = "CEP inválido";
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.zipcode)) {
        valid = false;
        vm.invalidFields.zipcode = true;
        vm.specificFieldsMessage.cep = "Campo obrigatório";
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.street)) {
        valid = false;
        vm.invalidFields.street = true;
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.number)) {
        valid = false;
        vm.invalidFields.number = true;
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.city)) {
        valid = false;
        vm.invalidFields.city = true;
        vm.clearButtonLoading();
      }
      if (vm.testNullUndefinedEmpty(vm.state)) {
        valid = false;
        vm.invalidFields.state = true;
        vm.clearButtonLoading();
      }

      if (!this.user.termsAccepted && !vm.termsAccepted) {
        valid = false;
        vm.termsAlertControl = true;
        vm.clearButtonLoading();
      } else {
        vm.termsAlertControl = false;
        vm.clearButtonLoading();
      }

      if (vm.password != vm.password2) {
        valid = false;
        vm.invalidFields.password2 = true;
        vm.specificFieldsMessage.password2 = "A senha e a confirmação de senha não conferem";
        vm.clearButtonLoading();
      }
      const regex = /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      if (regex.test(vm.password) == false && vm.testNullUndefinedEmpty(vm.password) == false) {
        valid = false;
        vm.invalidFields.password1 = true;
        vm.specificFieldsMessage.password1 = vm.weakPassword;
        vm.weakPasswordWarning = true;
        vm.clearButtonLoading();
      }
      if (valid === false) {
        vm.fieldsWarning = true;
        vm.clearButtonLoading();
      } else {
        vm.finalizeCustomerRegistration();
      }
    },
    finalizeCustomerRegistration() {
      const vm = this;
      const user = {
        name: vm.name,
        gender: vm.gender,
        email: vm.email,
        phone: vm.phone,
        birthdate: vm.birthdate,
        address: {
          zipcode: vm.zipcode,
          street: vm.street,
          number: vm.number,
          complement: vm.complement,
          city: vm.city,
          state: vm.state,
        },
        facebookAccessToken: null,
        password: vm.password,
        acceptanceLocation : "primeiro acesso",
      };

      if (!this.user.termsAccepted) {
        user.termsAccepted = this.termsAccepted
      }

      vm.axios
        .put(vm.$store.state.filooServer + "signup/" + vm.user.id, user)
        .then(function (response) {
          if (response.status === 200) {
            vm.$bvModal.show("modal-finalize-registration-successfully");
          } else {
            window.scrollTo(0, 0);
            vm.$vs.notify({
              title: "Atenção!",
              text:
                "Erro ao solicitar o login na página de pacientes. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
              color: "warning",
              position: "top-right",
              icon: "error",
              time: 10000,
              click: () => {
                window.open(
                  "https://web.whatsapp.com/send?phone=55011996507610&text=Olá",
                  "_blank"
                );
              },
            });
          }
        })
        .catch(function (error) {
          vm.msgResposta = "Erro" + error;
          console.error({
            method: "finalizeCustomerRegistration",
            endpoint: "signup/",
            error: error,
          });
          window.scrollTo(0, 0);
          vm.$vs.notify({
            title: "Atenção!",
            text:
              "Não foi possível realizar o cadastramento do paciente. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
            color: "warning",
            position: "top-right",
            icon: "error",
            time: 10000,
            click: () => {
              window.open(
                "https://web.whatsapp.com/send?phone=55011996507610&text=Olá",
                "_blank"
              );
            },
          });
        });
    },
    login(nLogin, nSenha) {
      const vm = this;
      vm.$bvModal.hide("modal-finalize-registration-successfully");
      vm.$session.destroy();
      vm.$vs.loading();
      vm.axios
        .post(vm.$store.state.filooServer + "patient/login", {
          username: nLogin,
          password: nSenha,
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.$session.start();
            vm.$session.set("bearer", response.headers.authorization);
            vm.bearer_code = response.headers.authorization;
            vm.getMe();
          }
        })
        .catch(function (error) {
          console.error({
            method: "login",
            endpoint: "patient/login",
            error: error,
          });
          window.scrollTo(0, 0);
          vm.$vs.notify({
            title: "Atenção!",
            text:
              "Não foi possível realizar o login. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
            color: "warning",
            position: "top-right",
            icon: "error",
            time: 10000,
            click: () => {
              window.open(
                "https://web.whatsapp.com/send?phone=55011996507610&text=Olá",
                "_blank"
              );
            },
          });
        })
        .finally(() => {
          this.$vs.loading.close(this.$refs.content)
        });
    },
    getMe() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "me", {
          headers: { Authorization: vm.bearer_code },
        })
        .then(function (response) {
          vm.options = response.data;
          vm.$session.set("name", vm.options.name);
          vm.$session.set("photo", vm.options.photo);
          if (vm.options.type === "PATIENT") {
            vm.$session.set("type", "Cliente");
          }
          vm.$session.set("customerData", vm.options);
          vm.$session.set("address", vm.options.address);
          vm.$session.set("cpf", vm.options.cpf);
          vm.$session.set("email", vm.options.email);
          vm.$session.set("name", vm.options.name);
          vm.$session.set("filooCard", vm.options.filooCard);
          vm.$session.set("customerId", vm.options.customerId);
          vm.$session.set("id", vm.options.id);
          vm.$session.set("planTypeId", vm.options.planTypeId);
          
          vm.$session.set("planValue", vm.options.planValue);
          vm.$session.set("customerPlan", vm.options.customerPlan);
          vm.$session.set("signatureId", vm.options.signatureId);
          vm.$session.set("projectPhoto", vm.options.projectPhoto);
          vm.$session.set("additionalLife", vm.options.additionalLife);
          localStorage.removeItem("planAndAddOnsData");
          vm.$session.set("isProject", vm.options.hasOwnProperty("isProject") ? vm.options.isProject : false);
          if(vm.testNullUndefinedEmpty(vm.options.customerPlan.partnerId)) {
            vm.getServicesRouteList();
          } else{
            vm.getPlanServices();
          }         
          vm.setBalance();
          vm.$session.set("servicesRoutes", ["/solicitation-and-exams"]);
        })
        .catch(function (error) {
          console.error({
            method: "getME",
            endpoint: "me",
            error: error,
          });
          window.scrollTo(0, 0);
          vm.$vs.notify({
            title: "Atenção!",
            text:
              "Não foi obter os dados cadastrados e iniciar a sessão. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
            color: "warning",
            position: "top-right",
            icon: "error",
            time: 10000,
            click: () => {
              window.open(
                "https://web.whatsapp.com/send?phone=55011996507610&text=Olá",
                "_blank"
              );
            },
          });
        });
    },
    getServicesRouteList(){
      const vm = this;
      vm.axios
        .get(`${vm.$store.state.filooServer}patient/services/${vm.options.patientId}`)
        .then(function (response) {
          if (response.status === 200) {
            if(!vm.testNullUndefinedEmpty(response.data.acquiredPlanServices)){
              const services = response.data.acquiredPlanServices;  
              vm.$session.set("planServices", services);        
              vm.$session.set(
                "servicesRoutes",
                services
                  .filter(srvc => srvc.route != null)
                  .map(srvc => srvc.route)
              ); 
              vm.setServicesNameForValidation(services);
            }else{
              vm.getPlanServices();
            }
          }
        })
        .catch(function (error) {
          console.error({
            error: error,
            endpoint: `${vm.$store.state.filooServer}patient/services/${vm.options.patientId}`,
            method: "getServices",
            http_method: "GET",
          });
        });
    },
    getPlanServices(){
      const vm = this;
      vm.$session.set("planServices", vm.options.customerPlan.planServices);        
      vm.$session.set(
        "servicesRoutes",
        vm.options.customerPlan.planServices
          .filter(srvc => srvc.route != null)
          .map(srvc => srvc.route)
      );
      vm.setServicesNameForValidation(vm.options.customerPlan.planServices);
    },
    setBalance() {
      const vm = this;
      vm.axios
        .get(this.$store.state.filooServer + "wallet/overview", {
          headers: {
            Authorization: this.$session.get("bearer"),
          },
        })
        .then(function (response) {
          vm.$vs.loading.close()
          vm.saldo = response.data.avaliableBalance;
          vm.$session.set("saldo", vm.saldo);
          vm.medRecId = parseInt(vm.$route.params.medRecId);
          if(vm.$route.query.redirect)
            vm.$router.push(`/${vm.$route.query.redirect}`);
          else {
            vm.$router.push("/plan-upgrade");
          }
        })
        .catch(function (error) {
          console.error({
            method: "setBalance",
            endpoint: "wallet/overview",
            error: error,
          });
          window.scrollTo(0, 0);
          vm.$vs.notify({
            title: "Atenção!",
            text:
              "Não foi possível obter o saldo e iniciar a sessão. Entre em contato com a equipe de atendimento para informar o ocorrido através do e-mail: contato@filoo.com.br ou pelo whatsapp 11 99650-7610.",
            color: "warning",
            position: "top-right",
            icon: "error",
            time: 10000,
            click: () => {
              window.open(
                "https://web.whatsapp.com/send?phone=55011996507610&text=Olá",
                "_blank"
              );
            },
          });
        });
    },
  },
  watch: {
    zipcode: function (newVal) {
      const vm = this;
      if (newVal.split("").length !== 9) return;
      if (vm.testNullUndefinedEmpty(newVal) == false) {
        vm.street = null;
        vm.city = null;
        vm.state = null;
        vm.number = null;
        vm.getZip();
      } else {
        vm.invalidFields.zipcode = true;
      }
    },
    gender: function () {
      const vm = this;
      vm.genderAlertControl = false;
    },
  },
};
</script>

<style lang="scss" src="./finalizeRegistration.scss">
</style>
